import React from 'react'
import './App.css'
import Hero from './components/Hero/Hero'
import Program from './components/program/Program'
import Reasons from './components/Reasons.jsx/Reasons'
import Plans from './components/Plans/Plans'
import Testimonial from './components/Testimonial/Testimonial'
import Join from './components/Join/Join'
import Footer from './components/Footer/Footer'


const App = () => {
  return (
    <div className='App'>
      <Hero />
      <Program />
      <Reasons />
      <Plans />
      <Testimonial />
      <Join />
      <Footer />
    </div>
  )
}

export default App
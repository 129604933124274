import React from 'react'
import './Program.css'
import { programsData } from '../../data/programsData';
import RightArrow from '../../assets/rightArrow.png'
const Program = () => {
    return (

        <div className='program' id='program'>
            {/* header  */}
            <div className='program-header'>
                <span className='strole-text'>EXPLORE OUR</span>
                <span>PROGRAMS</span>
                <span className='strole-text'>TO SHAPE YOU</span>
            </div>

            {/* p  */}
            <div className='program-categories'>
                {programsData.map((program) => (
                    <div className='category' key={program.heading}>
                        {program.image}
                        <span>{program.heading}</span>
                        <span>{program.details}</span>
                        <div className='join-now'><span>join Now</span>
                            <img src={RightArrow} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Program
import React from 'react';
import './Footer.css';
import Github from '../../assets/github.png';
import Instagram from '../../assets/web.png';
import Linkedin from '../../assets/linkedin.png';
import Logo from '../../assets/logo.png';

const Footer = () => {
    return (
        <div className='footer-container'>
            <hr />
            <div className='footer'>
                <div className='social'>
                    <a href='http://www.github.com/pradeepgupta77' target='_blank' rel='noopener noreferrer'>
                        <img src={Github} alt='GitHub' />
                    </a>
                    <a href='http://www.linkedin.com/in/pg-pradeepgupta' target='_blank' rel='noopener noreferrer'>
                        <img src={Linkedin} alt='LinkedIn' />
                    </a>
                    <a href='https://pradeepgupta77.github.io/My_portfolio/' target='_blank' rel='noopener noreferrer'>
                        <img src={Instagram} alt='Instagram' />
                    </a>
                </div>
                <div className='logo-f'>
                    <img src={Logo} alt='Logo' />
                </div>
            </div>
            <div className='blur footer-blur1'></div>
            <div className='blur footer-blur2'></div>
        </div>
    );
};

export default Footer;

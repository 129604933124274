import React, { useState } from 'react'
import './Testimonial.css'
import { testimonialsData } from '../../data/testimonialsData'
import leftArrow from '../../assets/leftArrow.png'
import rightArrow from '../../assets/rightArrow.png'
// fremer motion 
import { motion } from "framer-motion"

const Testimonial = () => {
    const transition = { type: 'spring', duration: 3 }
    const [selected, setselected] = useState(0);
    const tLenght = testimonialsData.length
    return (
        <div className='testimonial' id='testimonial'>
            <div className='left-t'>
                <span>TESTIMONIALS</span>
                <span className='strole-text'>WHAT THEY</span>
                <span>SAY ABOUT US</span>
                <motion.span
                    key={selected}
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 100 }}
                    transition={transition}
                >
                    {testimonialsData[selected].review}
                </motion.span>
                <span>
                    <span style={{ color: 'var(--origin)' }}>
                        {testimonialsData[selected].name}
                    </span>{' '}
                    -{testimonialsData[selected].status}
                </span>
            </div>
            <div className='right-t'>
                <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    transition={{ ...transition, duration: 2 }}
                    whileInView={{ opacity: 1, x: 0 }}
                ></motion.div>
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    transition={{ ...transition, duration: 2 }}
                    whileInView={{ opacity: 1, x: 0 }}
                ></motion.div>
                <motion.img
                    key={selected}
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={transition}
                    src={testimonialsData[selected].image} />
                <div className='arrows'>
                    <img
                        onClick={() => (
                            selected === 0 ? setselected(tLenght - 1) : setselected((prev) => prev - 1)
                        )}
                        src={leftArrow} />
                    <img
                        onClick={() => (
                            selected === tLenght - 1 ? setselected(0) : setselected((prev) => prev + 1)
                        )}
                        src={rightArrow} />
                </div>
            </div>
        </div>
    )
}

export default Testimonial
import React, { useState } from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
// import
import { Link } from 'react-scroll'

const Header = () => {
    const mobile = window.innerWidth <= 768 ? true : false;
    const [menuOpend, setmenuOpened] = useState(false)
    return (
        <div className='header'>
            <img src={Logo} alt='logo' className='logo' />
            {menuOpend === false && mobile === true ? (
                <div
                    style={{ backgroundColor: 'var(--appColor)', padding: '0.5rem', borderRadius: '5px' }}
                    onClick={() => setmenuOpened(true)}
                >
                    <img src={Bars} style={{ width: '1.5rem', height: '1.5rem' }} />
                </div>
            ) :
                <ul className='header-menu' id='mobo'>
                    <li><Link onClick={() => setmenuOpened(false)}
                        activeClass='active'
                        to='hero'
                        span={true}
                        smooth={true}
                    >Home</Link></li>

                    <li ><Link onClick={() => setmenuOpened(false)}
                        to='program'
                        span={true}
                        smooth={true}
                    >Program</Link></li>

                    <li ><Link onClick={() => setmenuOpened(false)}
                        to='reasons'
                        span={true}
                        smooth={true}
                    >Why us</Link></li>

                    <li ><Link onClick={() => setmenuOpened(false)}
                        to='plans'
                        span={true}
                        smooth={true}
                    >Plans</Link></li>

                    <li><Link
                        onClick={() => setmenuOpened(false)}
                        to='testimonial'
                        span={true}
                        smooth={true}
                    >Testimonials</Link></li>
                </ul>}

        </div>
    )
}

export default Header
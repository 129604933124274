import React from 'react'
import Header from '../Header/Header'
import './Hero.css'

// img 
import Hero_img from '../../assets/hero_image.png'
import Hero_img_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import Calories from '../../assets/calories.png'
import NumberCounter from 'number-counter'

// fremer motion 
import { motion } from "framer-motion"
const Hero = () => {

    const transition = { type: 'spring', duration: 3 }
    const mobile = window.innerWidth <= 768 ? true : false;
    return (
        <>
            <div className='hero' id='hero'>
                <div className='blur hero-blur'></div>
                <div className='left-h'>
                    <Header />
                    {/* the best add  */}
                    <div className='the-best-ad'>
                        <motion.div
                            initial={{ left: mobile ? "178px" : '238px' }}
                            whileInView={{ left: '8px' }}
                            transition={{ ...transition, type: 'tween' }}
                        ></motion.div>
                        <span>
                            THE BEST FITNESS CLUB IN THE TOWN</span>

                    </div>
                    {/* hero text  */}
                    <div className='hero-text'>
                        <div>
                            <span className='strole-text'>SHAPE</span>
                            <span>YOUR</span>
                        </div>
                        <div>
                            <span>IDEAL BODY</span>
                        </div>
                        <div>
                            <span>
                                In here we will help you to shape and build your ideal body and live up your life to fullest
                            </span>
                        </div>
                    </div>

                    {/* figures */}
                    <div className='figures'>
                        <div>
                            <span>
                                <NumberCounter end={140} start={100} delay='4' preFix='+' />
                            </span>
                            <span>EXPERT COACHES</span>
                        </div>
                        <div>
                            <span>
                                <NumberCounter end={978} start={800} delay='4' preFix='+' />
                            </span>
                            <span>MEMBERS JOINED</span>
                        </div>
                        <div>
                            <span>
                                <NumberCounter end={50} start={20} delay='4' preFix='+' />
                            </span>
                            <span>FITNESS PROGRAMS</span>
                        </div>

                    </div>

                    {/* hero btn  */}
                    <div className='hero-btn'>
                        <button className='btn'>Get Started</button>
                        <button className='btn'>Learn More</button>
                    </div>
                </div>
                <div className='right-h'>
                    <button className='btn'>Join Now</button>

                    <motion.div
                        initial={{ right: '-4rem' }}
                        whileInView={{ right: '4rem' }}
                        transition={transition}
                        className='heart-rate'>
                        <img src={Heart} />
                        <span>Heart Rate</span><span> 116 bpm</span>
                    </motion.div>

                    {/* hero img  */}
                    <img src={Hero_img} className='hero-img' />
                    <motion.img
                        initial={{ right: '11rem' }}
                        whileInView={{ right: '20rem' }}
                        transition={transition}
                        src={Hero_img_back} className='hero-img-back' />

                    {/* Calories */}
                    <motion.div
                        initial={{ right: '37rem' }}
                        whileInView={{ right: '28rem' }}
                        transition={transition}
                        className='calories'>
                        <img src={Calories} />
                        <div>

                            <span>Calories burned</span><span>220 kcal</span>
                        </div>
                    </motion.div>
                </div>
            </div>
        </>
    )
}

export default Hero
import React, { useRef } from 'react';
import './Join.css';
import emailjs from '@emailjs/browser';

const Join = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_6q08a05', 'template_jgxzp3s', form.current, 'fRfs2LSvthM5OQzY8')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div className='join' id='join'>
            <div className='left-j'>
                <hr />
                <div>
                    <span className='strole-text'>READY TO </span>
                    <span >LEVEL UP</span>
                </div>
                <div>
                    <span >YOUR BODY </span>
                    <span className='strole-text'>WITH US?</span>
                </div>
            </div>
            <div className='right-j'>
                <form ref={form} className='email-container' onSubmit={sendEmail}>
                    <input type='email' name='user_email' placeholder='Enter your Email Address here...' />
                    <button className='btn btn-j'>Join now</button>
                </form>
            </div>
        </div>
    );
};

export default Join;
